@import url('https://rsms.me/inter/inter.css');

body {
  margin: 0;
}
html,
body,
#root {
  height: 100%;
  font-variant-numeric: tabular-nums;
  font-family: 'Inter var', sans-serif;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 3s ease 0.5s forwards;
}
